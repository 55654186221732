<template>
  <span class="monospace text-no-wrap">
    <span v-if="item.tags.includes('kostenlos_leihweise')">
      {{ prepend }}{{ $t("free loan") }}
    </span>
    <span v-else-if="item.tags.includes('geschenkt')">
      {{ prepend }}{{ $t("given") }}
    </span>
    <span v-else-if="item.tags.includes('nichts_anzeigen')"></span>
    <template v-else>
      <span v-if="item.price_fix">
        {{ prepend }}{{ formatMoney(item.price_fix, "CHF") }}
      </span>
      <span v-if="item.price_fix && item.price_recurring">+</span>
      <span v-if="item.price_recurring">
        {{ prepend }}{{ formatMoney(item.price_recurring, "CHF") }}
        <span class="ml-1">{{ $t("mthly") }}</span>
      </span>
    </template>
    <hardware-shop-item-sale :item="item" />
  </span>
</template>

<script>
import formatMoney from "@/utils/mixins/formatMoney";
import HardwareShopItemSale from "./HardwareShopItemSale";

export default {
  name: "HardwareShopItemPrice",
  components: {
    HardwareShopItemSale,
  },
  mixins: [formatMoney],
  props: {
    item: {
      type: Object,
      required: true,
    },
    prepend: {
      type: String,
      default: null,
    },
  },
};
</script>

<i18n>
{
  "en": {
    "free loan": "Free loanly",
    "givent": "Given",
    "mthly": "mthly"
  }, 
  "de": {
    "free loan": "Leihweise",
    "givent": "Geschenkt",
    "mthly": "mtl."
  }
}
</i18n>
