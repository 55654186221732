<template>
  <v-btn
    v-if="itemsInCart > 0"
    fixed
    fab
    top
    right
    color="secondary"
    class="mt-15 mr-n2"
    @click="$emit('openCart')"
  >
    <v-badge :content="itemsInCart" :value="itemsInCart" color="secondary">
      <v-icon>mdi-cart-outline</v-icon>
    </v-badge>
  </v-btn>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("hardwareShop");

export default {
  name: "HardwareShopBadge",
  computed: {
    ...mapGetters(["itemsInCart"]),
  },
};
</script>
